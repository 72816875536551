/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { AddPackageToProductGroupRequestRequest } from '../model/addPackageToProductGroupRequestRequest';
// @ts-ignore
import { BarcodeRequestRequest } from '../model/barcodeRequestRequest';
// @ts-ignore
import { BarcodeResponse } from '../model/barcodeResponse';
// @ts-ignore
import { Brand } from '../model/brand';
// @ts-ignore
import { BrandMaster } from '../model/brandMaster';
// @ts-ignore
import { BrandRequest } from '../model/brandRequest';
// @ts-ignore
import { Category } from '../model/category';
// @ts-ignore
import { CategoryType } from '../model/categoryType';
// @ts-ignore
import { HasProductUpdate } from '../model/hasProductUpdate';
// @ts-ignore
import { MasterDataForProductPage } from '../model/masterDataForProductPage';
// @ts-ignore
import { MinimumProductGroupMaster } from '../model/minimumProductGroupMaster';
// @ts-ignore
import { PaginatedBarcodeResponseList } from '../model/paginatedBarcodeResponseList';
// @ts-ignore
import { PaginatedBrandMasterList } from '../model/paginatedBrandMasterList';
// @ts-ignore
import { PaginatedMinimumProductGroupMasterList } from '../model/paginatedMinimumProductGroupMasterList';
// @ts-ignore
import { PaginatedMinimumProductPackageWithPromotionList } from '../model/paginatedMinimumProductPackageWithPromotionList';
// @ts-ignore
import { PatchedBrandRequest } from '../model/patchedBrandRequest';
// @ts-ignore
import { PatchedProductGroupRequest } from '../model/patchedProductGroupRequest';
// @ts-ignore
import { PostProductPackage } from '../model/postProductPackage';
// @ts-ignore
import { Product } from '../model/product';
// @ts-ignore
import { ProductGroup } from '../model/productGroup';
// @ts-ignore
import { ProductGroupRequest } from '../model/productGroupRequest';
// @ts-ignore
import { ProductPackage } from '../model/productPackage';
// @ts-ignore
import { ProductPost } from '../model/productPost';
// @ts-ignore
import { SetDefaultRequestRequest } from '../model/setDefaultRequestRequest';
// @ts-ignore
import { WeightUnitEnum } from '../model/weightUnitEnum';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface ProductsBarcodeCreateRequestParams {
    barcodeRequestRequest: BarcodeRequestRequest;
}

export interface ProductsBrandMastersListRequestParams {
    /** The pagination cursor value. */
    cursor?: string;
    name?: string;
    /** Number of results to return per page. */
    pageSize?: number;
}

export interface ProductsBrandMastersRetrieveRequestParams {
    /** A unique integer value identifying this brand master. */
    id: number;
}

export interface ProductsBrandsCreateRequestParams {
    brandRequest: BrandRequest;
}

export interface ProductsBrandsDestroyRequestParams {
    /** A unique integer value identifying this brand. */
    id: number;
}

export interface ProductsBrandsListRequestParams {
    name?: string;
}

export interface ProductsBrandsPartialUpdateRequestParams {
    /** A unique integer value identifying this brand. */
    id: number;
    patchedBrandRequest?: PatchedBrandRequest;
}

export interface ProductsBrandsRetrieveRequestParams {
    /** A unique integer value identifying this brand. */
    id: number;
}

export interface ProductsBrandsUpdateRequestParams {
    /** A unique integer value identifying this brand. */
    id: number;
    brandRequest: BrandRequest;
}

export interface ProductsCategoriesRetrieveRequestParams {
    /** A unique integer value identifying this category. */
    id: number;
}

export interface ProductsCreateRequestParams {
    wholesale: number;
    name: string;
    brand: string;
    primaryCategory: number;
    price: string;
    packingSize: string;
    unitPerPackingSize: number;
    isPublic: boolean;
    isCustomName?: boolean;
    productSku?: string;
    isPin?: boolean;
    productGroup?: string | null;
    secondaryCategory?: number | null;
    subCategory?: number | null;
    property?: string;
    picture?: Blob | null;
    barcode?: string;
    width?: number | null;
    height?: number | null;
    length?: number | null;
    weight?: number | null;
    weightUnit?: WeightUnitEnum;
    vat?: string | null;
    isTemporarilyUnavailable?: boolean;
    isNotForSell?: boolean;
}

export interface ProductsPackagesCreateRequestParams {
    productSku: string;
    wholesale: number;
    barcode: string;
    packingSize: string;
    unitPerPackingSize: number;
    product: number;
    isTemporarilyUnavailable?: boolean;
    picture?: Blob;
    width?: number | null;
    height?: number | null;
    length?: number | null;
    weight?: number | null;
    weightUnit?: WeightUnitEnum;
    price?: string;
    vat?: number | null;
    isPublic?: boolean;
    isDefault?: boolean;
    isNotForSell?: boolean;
}

export interface ProductsPackagesDestroyRequestParams {
    /** A unique integer value identifying this product package. */
    id: number;
}

export interface ProductsPackagesDismissMasterNotificationCreateRequestParams {
    /** A unique integer value identifying this product package. */
    id: number;
}

export interface ProductsPackagesForPromotionListRequestParams {
    /** promotion  * &#x60;FLAT_DISCOUNT&#x60; - Flat Discount * &#x60;PROGRESSIVE&#x60; - Progressive * &#x60;BUY_WITH_DISCOUNT&#x60; - Buy With Discount * &#x60;BOGO&#x60; - Bogo */
    promoType: 'BOGO' | 'BUY_WITH_DISCOUNT' | 'FLAT_DISCOUNT' | 'PROGRESSIVE';
    /** The pagination cursor value. */
    cursor?: string;
    /** display_name */
    displayName?: string;
    isBogoPackage?: boolean;
    isNotForSell?: boolean;
    /** Number of results to return per page. */
    pageSize?: number;
    primaryCategoryId?: string;
    /** search */
    search?: string;
}

export interface ProductsPackagesForPromotionRetrieveRequestParams {
    /** A unique integer value identifying this product package. */
    id: number;
}

export interface ProductsPackagesListRequestParams {
    brand?: number;
    /** The pagination cursor value. */
    cursor?: string;
    /** display_name */
    displayName?: string;
    hasPromotion?: boolean;
    isDefault?: boolean;
    isNotForSell?: boolean;
    isPin?: boolean;
    isPublic?: boolean;
    isTemporarilyUnavailable?: boolean;
    name?: string;
    notifyUpdateFromMaster?: boolean;
    /** Number of results to return per page. */
    pageSize?: number;
    primaryCategory?: string;
    primaryCategoryId?: string;
    product?: number;
    productGroup?: number;
    productGroupNull?: boolean;
    /** search */
    search?: string;
    secondaryCategory?: string;
    subCategory?: string;
}

export interface ProductsPackagesMasterListRequestParams {
    brand?: number;
    /** The pagination cursor value. */
    cursor?: string;
    /** display_name */
    displayName?: string;
    hasPromotion?: boolean;
    isDefault?: boolean;
    isNotForSell?: boolean;
    isPublic?: boolean;
    isSelectAllCategory?: boolean;
    name?: string;
    notifyUpdateFromMaster?: boolean;
    /** Number of results to return per page. */
    pageSize?: number;
    primaryCategory?: number;
    primaryCategoryId?: string;
    product?: number;
    productGroup?: number;
    productGroupNull?: boolean;
    /** search */
    search?: string;
    secondaryCategory?: number;
    subCategory?: number;
}

export interface ProductsPackagesMasterRetrieveRequestParams {
    /** A unique integer value identifying this product package master. */
    id: number;
}

export interface ProductsPackagesPartialUpdateRequestParams {
    /** A unique integer value identifying this product package. */
    id: number;
    name?: string;
    brand?: string;
    productGroup?: string | null;
    primaryCategory?: number;
    secondaryCategory?: number | null;
    subCategory?: number | null;
    property?: string;
    picture?: Blob | null;
    barcode?: string;
    width?: number | null;
    height?: number | null;
    length?: number | null;
    weight?: number | null;
    weightUnit?: WeightUnitEnum;
    price?: string;
    packingSize?: string;
    unitPerPackingSize?: number;
    vat?: number | null;
    isDefault?: boolean;
    isPublic?: boolean;
    isNotForSell?: boolean;
    isPin?: boolean;
    productPackageMaster?: number | null;
    productSku?: string;
    isTemporarilyUnavailable?: boolean;
    isCustomName?: boolean;
}

export interface ProductsPackagesRetrieveRequestParams {
    /** A unique integer value identifying this product package. */
    id: number;
}

export interface ProductsPackagesSetDefaultAndDeleteMainCreateRequestParams {
    /** A unique integer value identifying this product package. */
    id: number;
    setDefaultRequestRequest: SetDefaultRequestRequest;
}

export interface ProductsPackagesSetDefaultCreateRequestParams {
    /** A unique integer value identifying this product package. */
    id: number;
    setDefaultRequestRequest: SetDefaultRequestRequest;
}

export interface ProductsPackagesUpdateRequestParams {
    /** A unique integer value identifying this product package. */
    id: number;
    name: string;
    brand: string;
    primaryCategory: number;
    property: string;
    packingSize: string;
    unitPerPackingSize: number;
    isDefault: boolean;
    isPublic: boolean;
    productGroup?: string | null;
    secondaryCategory?: number | null;
    subCategory?: number | null;
    picture?: Blob | null;
    barcode?: string;
    width?: number | null;
    height?: number | null;
    length?: number | null;
    weight?: number | null;
    weightUnit?: WeightUnitEnum;
    price?: string;
    vat?: number | null;
    isNotForSell?: boolean;
    isPin?: boolean;
    productPackageMaster?: number | null;
    productSku?: string;
    isTemporarilyUnavailable?: boolean;
    isCustomName?: boolean;
}

export interface ProductsProductGroupMastersListRequestParams {
    brand?: number;
    /** The pagination cursor value. */
    cursor?: string;
    name?: string;
    notCreatedByWs?: number;
    /** Number of results to return per page. */
    pageSize?: number;
}

export interface ProductsProductGroupMastersRetrieveRequestParams {
    /** A unique integer value identifying this product group master. */
    id: number;
}

export interface ProductsProductGroupsAddProductPackageCreateRequestParams {
    /** A unique integer value identifying this product group. */
    id: number;
    addPackageToProductGroupRequestRequest: AddPackageToProductGroupRequestRequest;
}

export interface ProductsProductGroupsCreateRequestParams {
    productGroupRequest: ProductGroupRequest;
}

export interface ProductsProductGroupsDestroyRequestParams {
    /** A unique integer value identifying this product group. */
    id: number;
}

export interface ProductsProductGroupsListRequestParams {
    brand?: number;
    name?: string;
}

export interface ProductsProductGroupsPartialUpdateRequestParams {
    /** A unique integer value identifying this product group. */
    id: number;
    patchedProductGroupRequest?: PatchedProductGroupRequest;
}

export interface ProductsProductGroupsRetrieveRequestParams {
    /** A unique integer value identifying this product group. */
    id: number;
}

export interface ProductsProductGroupsUpdateRequestParams {
    /** A unique integer value identifying this product group. */
    id: number;
    productGroupRequest: ProductGroupRequest;
}

export interface ProductsRetrieveRequestParams {
    /** A unique integer value identifying this product. */
    id: number;
}


@Injectable({
  providedIn: 'root'
})
export class ProductsService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsBarcodeCreate(requestParameters: ProductsBarcodeCreateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<BarcodeResponse>;
    public productsBarcodeCreate(requestParameters: ProductsBarcodeCreateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<BarcodeResponse>>;
    public productsBarcodeCreate(requestParameters: ProductsBarcodeCreateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<BarcodeResponse>>;
    public productsBarcodeCreate(requestParameters: ProductsBarcodeCreateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const barcodeRequestRequest = requestParameters.barcodeRequestRequest;
        if (barcodeRequestRequest === null || barcodeRequestRequest === undefined) {
            throw new Error('Required parameter barcodeRequestRequest was null or undefined when calling productsBarcodeCreate.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'multipart/form-data'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/barcode`;
        return this.httpClient.request<BarcodeResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: barcodeRequestRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsBrandMastersList(requestParameters: ProductsBrandMastersListRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PaginatedBrandMasterList>;
    public productsBrandMastersList(requestParameters: ProductsBrandMastersListRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PaginatedBrandMasterList>>;
    public productsBrandMastersList(requestParameters: ProductsBrandMastersListRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PaginatedBrandMasterList>>;
    public productsBrandMastersList(requestParameters: ProductsBrandMastersListRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const cursor = requestParameters.cursor;
        const name = requestParameters.name;
        const pageSize = requestParameters.pageSize;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (cursor !== undefined && cursor !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>cursor, 'cursor');
        }
        if (name !== undefined && name !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>name, 'name');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'page_size');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/brand-masters/`;
        return this.httpClient.request<PaginatedBrandMasterList>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsBrandMastersRetrieve(requestParameters: ProductsBrandMastersRetrieveRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<BrandMaster>;
    public productsBrandMastersRetrieve(requestParameters: ProductsBrandMastersRetrieveRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<BrandMaster>>;
    public productsBrandMastersRetrieve(requestParameters: ProductsBrandMastersRetrieveRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<BrandMaster>>;
    public productsBrandMastersRetrieve(requestParameters: ProductsBrandMastersRetrieveRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling productsBrandMastersRetrieve.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/brand-masters/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/`;
        return this.httpClient.request<BrandMaster>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsBrandsCreate(requestParameters: ProductsBrandsCreateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Brand>;
    public productsBrandsCreate(requestParameters: ProductsBrandsCreateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Brand>>;
    public productsBrandsCreate(requestParameters: ProductsBrandsCreateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Brand>>;
    public productsBrandsCreate(requestParameters: ProductsBrandsCreateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const brandRequest = requestParameters.brandRequest;
        if (brandRequest === null || brandRequest === undefined) {
            throw new Error('Required parameter brandRequest was null or undefined when calling productsBrandsCreate.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'multipart/form-data'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/brands/`;
        return this.httpClient.request<Brand>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: brandRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsBrandsDestroy(requestParameters: ProductsBrandsDestroyRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public productsBrandsDestroy(requestParameters: ProductsBrandsDestroyRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public productsBrandsDestroy(requestParameters: ProductsBrandsDestroyRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public productsBrandsDestroy(requestParameters: ProductsBrandsDestroyRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling productsBrandsDestroy.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/brands/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsBrandsList(requestParameters: ProductsBrandsListRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<Brand>>;
    public productsBrandsList(requestParameters: ProductsBrandsListRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<Brand>>>;
    public productsBrandsList(requestParameters: ProductsBrandsListRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<Brand>>>;
    public productsBrandsList(requestParameters: ProductsBrandsListRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const name = requestParameters.name;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (name !== undefined && name !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>name, 'name');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/brands/`;
        return this.httpClient.request<Array<Brand>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsBrandsPartialUpdate(requestParameters: ProductsBrandsPartialUpdateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Brand>;
    public productsBrandsPartialUpdate(requestParameters: ProductsBrandsPartialUpdateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Brand>>;
    public productsBrandsPartialUpdate(requestParameters: ProductsBrandsPartialUpdateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Brand>>;
    public productsBrandsPartialUpdate(requestParameters: ProductsBrandsPartialUpdateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling productsBrandsPartialUpdate.');
        }
        const patchedBrandRequest = requestParameters.patchedBrandRequest;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'multipart/form-data'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/brands/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/`;
        return this.httpClient.request<Brand>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: patchedBrandRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsBrandsRetrieve(requestParameters: ProductsBrandsRetrieveRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Brand>;
    public productsBrandsRetrieve(requestParameters: ProductsBrandsRetrieveRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Brand>>;
    public productsBrandsRetrieve(requestParameters: ProductsBrandsRetrieveRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Brand>>;
    public productsBrandsRetrieve(requestParameters: ProductsBrandsRetrieveRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling productsBrandsRetrieve.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/brands/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/`;
        return this.httpClient.request<Brand>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsBrandsUpdate(requestParameters: ProductsBrandsUpdateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Brand>;
    public productsBrandsUpdate(requestParameters: ProductsBrandsUpdateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Brand>>;
    public productsBrandsUpdate(requestParameters: ProductsBrandsUpdateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Brand>>;
    public productsBrandsUpdate(requestParameters: ProductsBrandsUpdateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling productsBrandsUpdate.');
        }
        const brandRequest = requestParameters.brandRequest;
        if (brandRequest === null || brandRequest === undefined) {
            throw new Error('Required parameter brandRequest was null or undefined when calling productsBrandsUpdate.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'multipart/form-data'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/brands/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/`;
        return this.httpClient.request<Brand>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: brandRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsCategoriesList(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<Category>>;
    public productsCategoriesList(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<Category>>>;
    public productsCategoriesList(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<Category>>>;
    public productsCategoriesList(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/categories/`;
        return this.httpClient.request<Array<Category>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsCategoriesRetrieve(requestParameters: ProductsCategoriesRetrieveRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Category>;
    public productsCategoriesRetrieve(requestParameters: ProductsCategoriesRetrieveRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Category>>;
    public productsCategoriesRetrieve(requestParameters: ProductsCategoriesRetrieveRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Category>>;
    public productsCategoriesRetrieve(requestParameters: ProductsCategoriesRetrieveRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling productsCategoriesRetrieve.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/categories/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/`;
        return this.httpClient.request<Category>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get enum list of category types
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsCategoriesTypesList(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<CategoryType>>;
    public productsCategoriesTypesList(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<CategoryType>>>;
    public productsCategoriesTypesList(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<CategoryType>>>;
    public productsCategoriesTypesList(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/categories/types/`;
        return this.httpClient.request<Array<CategoryType>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsCreate(requestParameters: ProductsCreateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ProductPost>;
    public productsCreate(requestParameters: ProductsCreateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ProductPost>>;
    public productsCreate(requestParameters: ProductsCreateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ProductPost>>;
    public productsCreate(requestParameters: ProductsCreateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const wholesale = requestParameters.wholesale;
        if (wholesale === null || wholesale === undefined) {
            throw new Error('Required parameter wholesale was null or undefined when calling productsCreate.');
        }
        const name = requestParameters.name;
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling productsCreate.');
        }
        const brand = requestParameters.brand;
        if (brand === null || brand === undefined) {
            throw new Error('Required parameter brand was null or undefined when calling productsCreate.');
        }
        const primaryCategory = requestParameters.primaryCategory;
        if (primaryCategory === null || primaryCategory === undefined) {
            throw new Error('Required parameter primaryCategory was null or undefined when calling productsCreate.');
        }
        const price = requestParameters.price;
        if (price === null || price === undefined) {
            throw new Error('Required parameter price was null or undefined when calling productsCreate.');
        }
        const packingSize = requestParameters.packingSize;
        if (packingSize === null || packingSize === undefined) {
            throw new Error('Required parameter packingSize was null or undefined when calling productsCreate.');
        }
        const unitPerPackingSize = requestParameters.unitPerPackingSize;
        if (unitPerPackingSize === null || unitPerPackingSize === undefined) {
            throw new Error('Required parameter unitPerPackingSize was null or undefined when calling productsCreate.');
        }
        const isPublic = requestParameters.isPublic;
        if (isPublic === null || isPublic === undefined) {
            throw new Error('Required parameter isPublic was null or undefined when calling productsCreate.');
        }
        const isCustomName = requestParameters.isCustomName;
        const productSku = requestParameters.productSku;
        const isPin = requestParameters.isPin;
        const productGroup = requestParameters.productGroup;
        const secondaryCategory = requestParameters.secondaryCategory;
        const subCategory = requestParameters.subCategory;
        const property = requestParameters.property;
        const picture = requestParameters.picture;
        const barcode = requestParameters.barcode;
        const width = requestParameters.width;
        const height = requestParameters.height;
        const length = requestParameters.length;
        const weight = requestParameters.weight;
        const weightUnit = requestParameters.weightUnit;
        const vat = requestParameters.vat;
        const isTemporarilyUnavailable = requestParameters.isTemporarilyUnavailable;
        const isNotForSell = requestParameters.isNotForSell;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data',
            'application/json'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (isCustomName !== undefined) {
            localVarFormParams = localVarFormParams.append('isCustomName', <any>isCustomName) as any || localVarFormParams;
        }
        if (productSku !== undefined) {
            localVarFormParams = localVarFormParams.append('productSku', <any>productSku) as any || localVarFormParams;
        }
        if (isPin !== undefined) {
            localVarFormParams = localVarFormParams.append('isPin', <any>isPin) as any || localVarFormParams;
        }
        if (wholesale !== undefined) {
            localVarFormParams = localVarFormParams.append('wholesale', <any>wholesale) as any || localVarFormParams;
        }
        if (name !== undefined) {
            localVarFormParams = localVarFormParams.append('name', <any>name) as any || localVarFormParams;
        }
        if (productGroup !== undefined) {
            localVarFormParams = localVarFormParams.append('productGroup', <any>productGroup) as any || localVarFormParams;
        }
        if (brand !== undefined) {
            localVarFormParams = localVarFormParams.append('brand', <any>brand) as any || localVarFormParams;
        }
        if (primaryCategory !== undefined) {
            localVarFormParams = localVarFormParams.append('primaryCategory', <any>primaryCategory) as any || localVarFormParams;
        }
        if (secondaryCategory !== undefined) {
            localVarFormParams = localVarFormParams.append('secondaryCategory', <any>secondaryCategory) as any || localVarFormParams;
        }
        if (subCategory !== undefined) {
            localVarFormParams = localVarFormParams.append('subCategory', <any>subCategory) as any || localVarFormParams;
        }
        if (property !== undefined) {
            localVarFormParams = localVarFormParams.append('property', <any>property) as any || localVarFormParams;
        }
        if (picture !== undefined) {
            localVarFormParams = localVarFormParams.append('picture', <any>picture) as any || localVarFormParams;
        }
        if (barcode !== undefined) {
            localVarFormParams = localVarFormParams.append('barcode', <any>barcode) as any || localVarFormParams;
        }
        if (width !== undefined) {
            localVarFormParams = localVarFormParams.append('width', <any>width) as any || localVarFormParams;
        }
        if (height !== undefined) {
            localVarFormParams = localVarFormParams.append('height', <any>height) as any || localVarFormParams;
        }
        if (length !== undefined) {
            localVarFormParams = localVarFormParams.append('length', <any>length) as any || localVarFormParams;
        }
        if (weight !== undefined) {
            localVarFormParams = localVarFormParams.append('weight', <any>weight) as any || localVarFormParams;
        }
        if (weightUnit !== undefined) {
            localVarFormParams = localVarFormParams.append('weightUnit', <any>weightUnit) as any || localVarFormParams;
        }
        if (price !== undefined) {
            localVarFormParams = localVarFormParams.append('price', <any>price) as any || localVarFormParams;
        }
        if (packingSize !== undefined) {
            localVarFormParams = localVarFormParams.append('packingSize', <any>packingSize) as any || localVarFormParams;
        }
        if (unitPerPackingSize !== undefined) {
            localVarFormParams = localVarFormParams.append('unitPerPackingSize', <any>unitPerPackingSize) as any || localVarFormParams;
        }
        if (vat !== undefined) {
            localVarFormParams = localVarFormParams.append('vat', <any>vat) as any || localVarFormParams;
        }
        if (isPublic !== undefined) {
            localVarFormParams = localVarFormParams.append('isPublic', <any>isPublic) as any || localVarFormParams;
        }
        if (isTemporarilyUnavailable !== undefined) {
            localVarFormParams = localVarFormParams.append('isTemporarilyUnavailable', <any>isTemporarilyUnavailable) as any || localVarFormParams;
        }
        if (isNotForSell !== undefined) {
            localVarFormParams = localVarFormParams.append('isNotForSell', <any>isNotForSell) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/`;
        return this.httpClient.request<ProductPost>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsList(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<Product>>;
    public productsList(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<Product>>>;
    public productsList(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<Product>>>;
    public productsList(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/`;
        return this.httpClient.request<Array<Product>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsMasterDataRetrieve(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<MasterDataForProductPage>;
    public productsMasterDataRetrieve(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<MasterDataForProductPage>>;
    public productsMasterDataRetrieve(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<MasterDataForProductPage>>;
    public productsMasterDataRetrieve(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/master-data`;
        return this.httpClient.request<MasterDataForProductPage>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsPackagesCreate(requestParameters: ProductsPackagesCreateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PostProductPackage>;
    public productsPackagesCreate(requestParameters: ProductsPackagesCreateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PostProductPackage>>;
    public productsPackagesCreate(requestParameters: ProductsPackagesCreateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PostProductPackage>>;
    public productsPackagesCreate(requestParameters: ProductsPackagesCreateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const productSku = requestParameters.productSku;
        if (productSku === null || productSku === undefined) {
            throw new Error('Required parameter productSku was null or undefined when calling productsPackagesCreate.');
        }
        const wholesale = requestParameters.wholesale;
        if (wholesale === null || wholesale === undefined) {
            throw new Error('Required parameter wholesale was null or undefined when calling productsPackagesCreate.');
        }
        const barcode = requestParameters.barcode;
        if (barcode === null || barcode === undefined) {
            throw new Error('Required parameter barcode was null or undefined when calling productsPackagesCreate.');
        }
        const packingSize = requestParameters.packingSize;
        if (packingSize === null || packingSize === undefined) {
            throw new Error('Required parameter packingSize was null or undefined when calling productsPackagesCreate.');
        }
        const unitPerPackingSize = requestParameters.unitPerPackingSize;
        if (unitPerPackingSize === null || unitPerPackingSize === undefined) {
            throw new Error('Required parameter unitPerPackingSize was null or undefined when calling productsPackagesCreate.');
        }
        const product = requestParameters.product;
        if (product === null || product === undefined) {
            throw new Error('Required parameter product was null or undefined when calling productsPackagesCreate.');
        }
        const isTemporarilyUnavailable = requestParameters.isTemporarilyUnavailable;
        const picture = requestParameters.picture;
        const width = requestParameters.width;
        const height = requestParameters.height;
        const length = requestParameters.length;
        const weight = requestParameters.weight;
        const weightUnit = requestParameters.weightUnit;
        const price = requestParameters.price;
        const vat = requestParameters.vat;
        const isPublic = requestParameters.isPublic;
        const isDefault = requestParameters.isDefault;
        const isNotForSell = requestParameters.isNotForSell;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data',
            'application/json'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (productSku !== undefined) {
            localVarFormParams = localVarFormParams.append('productSku', <any>productSku) as any || localVarFormParams;
        }
        if (wholesale !== undefined) {
            localVarFormParams = localVarFormParams.append('wholesale', <any>wholesale) as any || localVarFormParams;
        }
        if (isTemporarilyUnavailable !== undefined) {
            localVarFormParams = localVarFormParams.append('isTemporarilyUnavailable', <any>isTemporarilyUnavailable) as any || localVarFormParams;
        }
        if (picture !== undefined) {
            localVarFormParams = localVarFormParams.append('picture', <any>picture) as any || localVarFormParams;
        }
        if (barcode !== undefined) {
            localVarFormParams = localVarFormParams.append('barcode', <any>barcode) as any || localVarFormParams;
        }
        if (width !== undefined) {
            localVarFormParams = localVarFormParams.append('width', <any>width) as any || localVarFormParams;
        }
        if (height !== undefined) {
            localVarFormParams = localVarFormParams.append('height', <any>height) as any || localVarFormParams;
        }
        if (length !== undefined) {
            localVarFormParams = localVarFormParams.append('length', <any>length) as any || localVarFormParams;
        }
        if (weight !== undefined) {
            localVarFormParams = localVarFormParams.append('weight', <any>weight) as any || localVarFormParams;
        }
        if (weightUnit !== undefined) {
            localVarFormParams = localVarFormParams.append('weightUnit', <any>weightUnit) as any || localVarFormParams;
        }
        if (price !== undefined) {
            localVarFormParams = localVarFormParams.append('price', <any>price) as any || localVarFormParams;
        }
        if (packingSize !== undefined) {
            localVarFormParams = localVarFormParams.append('packingSize', <any>packingSize) as any || localVarFormParams;
        }
        if (unitPerPackingSize !== undefined) {
            localVarFormParams = localVarFormParams.append('unitPerPackingSize', <any>unitPerPackingSize) as any || localVarFormParams;
        }
        if (vat !== undefined) {
            localVarFormParams = localVarFormParams.append('vat', <any>vat) as any || localVarFormParams;
        }
        if (isPublic !== undefined) {
            localVarFormParams = localVarFormParams.append('isPublic', <any>isPublic) as any || localVarFormParams;
        }
        if (isDefault !== undefined) {
            localVarFormParams = localVarFormParams.append('isDefault', <any>isDefault) as any || localVarFormParams;
        }
        if (isNotForSell !== undefined) {
            localVarFormParams = localVarFormParams.append('isNotForSell', <any>isNotForSell) as any || localVarFormParams;
        }
        if (product !== undefined) {
            localVarFormParams = localVarFormParams.append('product', <any>product) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/packages/`;
        return this.httpClient.request<PostProductPackage>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsPackagesDestroy(requestParameters: ProductsPackagesDestroyRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public productsPackagesDestroy(requestParameters: ProductsPackagesDestroyRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public productsPackagesDestroy(requestParameters: ProductsPackagesDestroyRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public productsPackagesDestroy(requestParameters: ProductsPackagesDestroyRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling productsPackagesDestroy.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/packages/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsPackagesDismissMasterNotificationCreate(requestParameters: ProductsPackagesDismissMasterNotificationCreateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public productsPackagesDismissMasterNotificationCreate(requestParameters: ProductsPackagesDismissMasterNotificationCreateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public productsPackagesDismissMasterNotificationCreate(requestParameters: ProductsPackagesDismissMasterNotificationCreateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public productsPackagesDismissMasterNotificationCreate(requestParameters: ProductsPackagesDismissMasterNotificationCreateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling productsPackagesDismissMasterNotificationCreate.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/packages/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/dismiss-master-notification/`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsPackagesForPromotionList(requestParameters: ProductsPackagesForPromotionListRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PaginatedMinimumProductPackageWithPromotionList>;
    public productsPackagesForPromotionList(requestParameters: ProductsPackagesForPromotionListRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PaginatedMinimumProductPackageWithPromotionList>>;
    public productsPackagesForPromotionList(requestParameters: ProductsPackagesForPromotionListRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PaginatedMinimumProductPackageWithPromotionList>>;
    public productsPackagesForPromotionList(requestParameters: ProductsPackagesForPromotionListRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const promoType = requestParameters.promoType;
        if (promoType === null || promoType === undefined) {
            throw new Error('Required parameter promoType was null or undefined when calling productsPackagesForPromotionList.');
        }
        const cursor = requestParameters.cursor;
        const displayName = requestParameters.displayName;
        const isBogoPackage = requestParameters.isBogoPackage;
        const isNotForSell = requestParameters.isNotForSell;
        const pageSize = requestParameters.pageSize;
        const primaryCategoryId = requestParameters.primaryCategoryId;
        const search = requestParameters.search;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (cursor !== undefined && cursor !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>cursor, 'cursor');
        }
        if (displayName !== undefined && displayName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>displayName, 'display_name');
        }
        if (isBogoPackage !== undefined && isBogoPackage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isBogoPackage, 'is_bogo_package');
        }
        if (isNotForSell !== undefined && isNotForSell !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isNotForSell, 'is_not_for_sell');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'page_size');
        }
        if (primaryCategoryId !== undefined && primaryCategoryId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>primaryCategoryId, 'primary_category_id');
        }
        if (promoType !== undefined && promoType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>promoType, 'promo_type');
        }
        if (search !== undefined && search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>search, 'search');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/packages-for-promotion/`;
        return this.httpClient.request<PaginatedMinimumProductPackageWithPromotionList>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsPackagesForPromotionRetrieve(requestParameters: ProductsPackagesForPromotionRetrieveRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ProductPackage>;
    public productsPackagesForPromotionRetrieve(requestParameters: ProductsPackagesForPromotionRetrieveRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ProductPackage>>;
    public productsPackagesForPromotionRetrieve(requestParameters: ProductsPackagesForPromotionRetrieveRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ProductPackage>>;
    public productsPackagesForPromotionRetrieve(requestParameters: ProductsPackagesForPromotionRetrieveRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling productsPackagesForPromotionRetrieve.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/packages-for-promotion/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/`;
        return this.httpClient.request<ProductPackage>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsPackagesHasUpdateFromMasterRetrieve(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HasProductUpdate>;
    public productsPackagesHasUpdateFromMasterRetrieve(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<HasProductUpdate>>;
    public productsPackagesHasUpdateFromMasterRetrieve(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<HasProductUpdate>>;
    public productsPackagesHasUpdateFromMasterRetrieve(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/packages/has-update-from-master/`;
        return this.httpClient.request<HasProductUpdate>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsPackagesList(requestParameters: ProductsPackagesListRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PaginatedMinimumProductPackageWithPromotionList>;
    public productsPackagesList(requestParameters: ProductsPackagesListRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PaginatedMinimumProductPackageWithPromotionList>>;
    public productsPackagesList(requestParameters: ProductsPackagesListRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PaginatedMinimumProductPackageWithPromotionList>>;
    public productsPackagesList(requestParameters: ProductsPackagesListRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const brand = requestParameters.brand;
        const cursor = requestParameters.cursor;
        const displayName = requestParameters.displayName;
        const hasPromotion = requestParameters.hasPromotion;
        const isDefault = requestParameters.isDefault;
        const isNotForSell = requestParameters.isNotForSell;
        const isPin = requestParameters.isPin;
        const isPublic = requestParameters.isPublic;
        const isTemporarilyUnavailable = requestParameters.isTemporarilyUnavailable;
        const name = requestParameters.name;
        const notifyUpdateFromMaster = requestParameters.notifyUpdateFromMaster;
        const pageSize = requestParameters.pageSize;
        const primaryCategory = requestParameters.primaryCategory;
        const primaryCategoryId = requestParameters.primaryCategoryId;
        const product = requestParameters.product;
        const productGroup = requestParameters.productGroup;
        const productGroupNull = requestParameters.productGroupNull;
        const search = requestParameters.search;
        const secondaryCategory = requestParameters.secondaryCategory;
        const subCategory = requestParameters.subCategory;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (brand !== undefined && brand !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>brand, 'brand');
        }
        if (cursor !== undefined && cursor !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>cursor, 'cursor');
        }
        if (displayName !== undefined && displayName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>displayName, 'display_name');
        }
        if (hasPromotion !== undefined && hasPromotion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>hasPromotion, 'has_promotion');
        }
        if (isDefault !== undefined && isDefault !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isDefault, 'is_default');
        }
        if (isNotForSell !== undefined && isNotForSell !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isNotForSell, 'is_not_for_sell');
        }
        if (isPin !== undefined && isPin !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isPin, 'is_pin');
        }
        if (isPublic !== undefined && isPublic !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isPublic, 'is_public');
        }
        if (isTemporarilyUnavailable !== undefined && isTemporarilyUnavailable !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isTemporarilyUnavailable, 'is_temporarily_unavailable');
        }
        if (name !== undefined && name !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>name, 'name');
        }
        if (notifyUpdateFromMaster !== undefined && notifyUpdateFromMaster !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>notifyUpdateFromMaster, 'notify_update_from_master');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'page_size');
        }
        if (primaryCategory !== undefined && primaryCategory !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>primaryCategory, 'primary_category');
        }
        if (primaryCategoryId !== undefined && primaryCategoryId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>primaryCategoryId, 'primary_category_id');
        }
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }
        if (productGroup !== undefined && productGroup !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>productGroup, 'product_group');
        }
        if (productGroupNull !== undefined && productGroupNull !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>productGroupNull, 'product_group_null');
        }
        if (search !== undefined && search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>search, 'search');
        }
        if (secondaryCategory !== undefined && secondaryCategory !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>secondaryCategory, 'secondary_category');
        }
        if (subCategory !== undefined && subCategory !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>subCategory, 'sub_category');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/packages/`;
        return this.httpClient.request<PaginatedMinimumProductPackageWithPromotionList>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsPackagesMasterList(requestParameters: ProductsPackagesMasterListRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PaginatedBarcodeResponseList>;
    public productsPackagesMasterList(requestParameters: ProductsPackagesMasterListRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PaginatedBarcodeResponseList>>;
    public productsPackagesMasterList(requestParameters: ProductsPackagesMasterListRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PaginatedBarcodeResponseList>>;
    public productsPackagesMasterList(requestParameters: ProductsPackagesMasterListRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const brand = requestParameters.brand;
        const cursor = requestParameters.cursor;
        const displayName = requestParameters.displayName;
        const hasPromotion = requestParameters.hasPromotion;
        const isDefault = requestParameters.isDefault;
        const isNotForSell = requestParameters.isNotForSell;
        const isPublic = requestParameters.isPublic;
        const isSelectAllCategory = requestParameters.isSelectAllCategory;
        const name = requestParameters.name;
        const notifyUpdateFromMaster = requestParameters.notifyUpdateFromMaster;
        const pageSize = requestParameters.pageSize;
        const primaryCategory = requestParameters.primaryCategory;
        const primaryCategoryId = requestParameters.primaryCategoryId;
        const product = requestParameters.product;
        const productGroup = requestParameters.productGroup;
        const productGroupNull = requestParameters.productGroupNull;
        const search = requestParameters.search;
        const secondaryCategory = requestParameters.secondaryCategory;
        const subCategory = requestParameters.subCategory;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (brand !== undefined && brand !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>brand, 'brand');
        }
        if (cursor !== undefined && cursor !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>cursor, 'cursor');
        }
        if (displayName !== undefined && displayName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>displayName, 'display_name');
        }
        if (hasPromotion !== undefined && hasPromotion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>hasPromotion, 'has_promotion');
        }
        if (isDefault !== undefined && isDefault !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isDefault, 'is_default');
        }
        if (isNotForSell !== undefined && isNotForSell !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isNotForSell, 'is_not_for_sell');
        }
        if (isPublic !== undefined && isPublic !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isPublic, 'is_public');
        }
        if (isSelectAllCategory !== undefined && isSelectAllCategory !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isSelectAllCategory, 'is_select_all_category');
        }
        if (name !== undefined && name !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>name, 'name');
        }
        if (notifyUpdateFromMaster !== undefined && notifyUpdateFromMaster !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>notifyUpdateFromMaster, 'notify_update_from_master');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'page_size');
        }
        if (primaryCategory !== undefined && primaryCategory !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>primaryCategory, 'primary_category');
        }
        if (primaryCategoryId !== undefined && primaryCategoryId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>primaryCategoryId, 'primary_category_id');
        }
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }
        if (productGroup !== undefined && productGroup !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>productGroup, 'product_group');
        }
        if (productGroupNull !== undefined && productGroupNull !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>productGroupNull, 'product_group_null');
        }
        if (search !== undefined && search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>search, 'search');
        }
        if (secondaryCategory !== undefined && secondaryCategory !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>secondaryCategory, 'secondary_category');
        }
        if (subCategory !== undefined && subCategory !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>subCategory, 'sub_category');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/packages-master/`;
        return this.httpClient.request<PaginatedBarcodeResponseList>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsPackagesMasterRetrieve(requestParameters: ProductsPackagesMasterRetrieveRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ProductPackage>;
    public productsPackagesMasterRetrieve(requestParameters: ProductsPackagesMasterRetrieveRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ProductPackage>>;
    public productsPackagesMasterRetrieve(requestParameters: ProductsPackagesMasterRetrieveRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ProductPackage>>;
    public productsPackagesMasterRetrieve(requestParameters: ProductsPackagesMasterRetrieveRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling productsPackagesMasterRetrieve.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/packages-master/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/`;
        return this.httpClient.request<ProductPackage>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsPackagesPartialUpdate(requestParameters: ProductsPackagesPartialUpdateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ProductPackage>;
    public productsPackagesPartialUpdate(requestParameters: ProductsPackagesPartialUpdateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ProductPackage>>;
    public productsPackagesPartialUpdate(requestParameters: ProductsPackagesPartialUpdateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ProductPackage>>;
    public productsPackagesPartialUpdate(requestParameters: ProductsPackagesPartialUpdateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling productsPackagesPartialUpdate.');
        }
        const name = requestParameters.name;
        const brand = requestParameters.brand;
        const productGroup = requestParameters.productGroup;
        const primaryCategory = requestParameters.primaryCategory;
        const secondaryCategory = requestParameters.secondaryCategory;
        const subCategory = requestParameters.subCategory;
        const property = requestParameters.property;
        const picture = requestParameters.picture;
        const barcode = requestParameters.barcode;
        const width = requestParameters.width;
        const height = requestParameters.height;
        const length = requestParameters.length;
        const weight = requestParameters.weight;
        const weightUnit = requestParameters.weightUnit;
        const price = requestParameters.price;
        const packingSize = requestParameters.packingSize;
        const unitPerPackingSize = requestParameters.unitPerPackingSize;
        const vat = requestParameters.vat;
        const isDefault = requestParameters.isDefault;
        const isPublic = requestParameters.isPublic;
        const isNotForSell = requestParameters.isNotForSell;
        const isPin = requestParameters.isPin;
        const productPackageMaster = requestParameters.productPackageMaster;
        const productSku = requestParameters.productSku;
        const isTemporarilyUnavailable = requestParameters.isTemporarilyUnavailable;
        const isCustomName = requestParameters.isCustomName;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data',
            'application/json'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (name !== undefined) {
            localVarFormParams = localVarFormParams.append('name', <any>name) as any || localVarFormParams;
        }
        if (brand !== undefined) {
            localVarFormParams = localVarFormParams.append('brand', <any>brand) as any || localVarFormParams;
        }
        if (productGroup !== undefined) {
            localVarFormParams = localVarFormParams.append('productGroup', <any>productGroup) as any || localVarFormParams;
        }
        if (primaryCategory !== undefined) {
            localVarFormParams = localVarFormParams.append('primaryCategory', <any>primaryCategory) as any || localVarFormParams;
        }
        if (secondaryCategory !== undefined) {
            localVarFormParams = localVarFormParams.append('secondaryCategory', <any>secondaryCategory) as any || localVarFormParams;
        }
        if (subCategory !== undefined) {
            localVarFormParams = localVarFormParams.append('subCategory', <any>subCategory) as any || localVarFormParams;
        }
        if (property !== undefined) {
            localVarFormParams = localVarFormParams.append('property', <any>property) as any || localVarFormParams;
        }
        if (picture !== undefined) {
            localVarFormParams = localVarFormParams.append('picture', <any>picture) as any || localVarFormParams;
        }
        if (barcode !== undefined) {
            localVarFormParams = localVarFormParams.append('barcode', <any>barcode) as any || localVarFormParams;
        }
        if (width !== undefined) {
            localVarFormParams = localVarFormParams.append('width', <any>width) as any || localVarFormParams;
        }
        if (height !== undefined) {
            localVarFormParams = localVarFormParams.append('height', <any>height) as any || localVarFormParams;
        }
        if (length !== undefined) {
            localVarFormParams = localVarFormParams.append('length', <any>length) as any || localVarFormParams;
        }
        if (weight !== undefined) {
            localVarFormParams = localVarFormParams.append('weight', <any>weight) as any || localVarFormParams;
        }
        if (weightUnit !== undefined) {
            localVarFormParams = localVarFormParams.append('weightUnit', <any>weightUnit) as any || localVarFormParams;
        }
        if (price !== undefined) {
            localVarFormParams = localVarFormParams.append('price', <any>price) as any || localVarFormParams;
        }
        if (packingSize !== undefined) {
            localVarFormParams = localVarFormParams.append('packingSize', <any>packingSize) as any || localVarFormParams;
        }
        if (unitPerPackingSize !== undefined) {
            localVarFormParams = localVarFormParams.append('unitPerPackingSize', <any>unitPerPackingSize) as any || localVarFormParams;
        }
        if (vat !== undefined) {
            localVarFormParams = localVarFormParams.append('vat', <any>vat) as any || localVarFormParams;
        }
        if (isDefault !== undefined) {
            localVarFormParams = localVarFormParams.append('isDefault', <any>isDefault) as any || localVarFormParams;
        }
        if (isPublic !== undefined) {
            localVarFormParams = localVarFormParams.append('isPublic', <any>isPublic) as any || localVarFormParams;
        }
        if (isNotForSell !== undefined) {
            localVarFormParams = localVarFormParams.append('isNotForSell', <any>isNotForSell) as any || localVarFormParams;
        }
        if (isPin !== undefined) {
            localVarFormParams = localVarFormParams.append('isPin', <any>isPin) as any || localVarFormParams;
        }
        if (productPackageMaster !== undefined) {
            localVarFormParams = localVarFormParams.append('productPackageMaster', <any>productPackageMaster) as any || localVarFormParams;
        }
        if (productSku !== undefined) {
            localVarFormParams = localVarFormParams.append('productSku', <any>productSku) as any || localVarFormParams;
        }
        if (isTemporarilyUnavailable !== undefined) {
            localVarFormParams = localVarFormParams.append('isTemporarilyUnavailable', <any>isTemporarilyUnavailable) as any || localVarFormParams;
        }
        if (isCustomName !== undefined) {
            localVarFormParams = localVarFormParams.append('isCustomName', <any>isCustomName) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/packages/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/`;
        return this.httpClient.request<ProductPackage>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsPackagesRetrieve(requestParameters: ProductsPackagesRetrieveRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ProductPackage>;
    public productsPackagesRetrieve(requestParameters: ProductsPackagesRetrieveRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ProductPackage>>;
    public productsPackagesRetrieve(requestParameters: ProductsPackagesRetrieveRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ProductPackage>>;
    public productsPackagesRetrieve(requestParameters: ProductsPackagesRetrieveRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling productsPackagesRetrieve.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/packages/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/`;
        return this.httpClient.request<ProductPackage>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * set default boolean of a product package and delete previous set package
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsPackagesSetDefaultAndDeleteMainCreate(requestParameters: ProductsPackagesSetDefaultAndDeleteMainCreateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public productsPackagesSetDefaultAndDeleteMainCreate(requestParameters: ProductsPackagesSetDefaultAndDeleteMainCreateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public productsPackagesSetDefaultAndDeleteMainCreate(requestParameters: ProductsPackagesSetDefaultAndDeleteMainCreateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public productsPackagesSetDefaultAndDeleteMainCreate(requestParameters: ProductsPackagesSetDefaultAndDeleteMainCreateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling productsPackagesSetDefaultAndDeleteMainCreate.');
        }
        const setDefaultRequestRequest = requestParameters.setDefaultRequestRequest;
        if (setDefaultRequestRequest === null || setDefaultRequestRequest === undefined) {
            throw new Error('Required parameter setDefaultRequestRequest was null or undefined when calling productsPackagesSetDefaultAndDeleteMainCreate.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/packages/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/set-default-and-delete-main/`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: setDefaultRequestRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * set default boolean of a product package
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsPackagesSetDefaultCreate(requestParameters: ProductsPackagesSetDefaultCreateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public productsPackagesSetDefaultCreate(requestParameters: ProductsPackagesSetDefaultCreateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public productsPackagesSetDefaultCreate(requestParameters: ProductsPackagesSetDefaultCreateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public productsPackagesSetDefaultCreate(requestParameters: ProductsPackagesSetDefaultCreateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling productsPackagesSetDefaultCreate.');
        }
        const setDefaultRequestRequest = requestParameters.setDefaultRequestRequest;
        if (setDefaultRequestRequest === null || setDefaultRequestRequest === undefined) {
            throw new Error('Required parameter setDefaultRequestRequest was null or undefined when calling productsPackagesSetDefaultCreate.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/packages/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/set-default/`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: setDefaultRequestRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsPackagesUpdate(requestParameters: ProductsPackagesUpdateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ProductPackage>;
    public productsPackagesUpdate(requestParameters: ProductsPackagesUpdateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ProductPackage>>;
    public productsPackagesUpdate(requestParameters: ProductsPackagesUpdateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ProductPackage>>;
    public productsPackagesUpdate(requestParameters: ProductsPackagesUpdateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling productsPackagesUpdate.');
        }
        const name = requestParameters.name;
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling productsPackagesUpdate.');
        }
        const brand = requestParameters.brand;
        if (brand === null || brand === undefined) {
            throw new Error('Required parameter brand was null or undefined when calling productsPackagesUpdate.');
        }
        const primaryCategory = requestParameters.primaryCategory;
        if (primaryCategory === null || primaryCategory === undefined) {
            throw new Error('Required parameter primaryCategory was null or undefined when calling productsPackagesUpdate.');
        }
        const property = requestParameters.property;
        if (property === null || property === undefined) {
            throw new Error('Required parameter property was null or undefined when calling productsPackagesUpdate.');
        }
        const packingSize = requestParameters.packingSize;
        if (packingSize === null || packingSize === undefined) {
            throw new Error('Required parameter packingSize was null or undefined when calling productsPackagesUpdate.');
        }
        const unitPerPackingSize = requestParameters.unitPerPackingSize;
        if (unitPerPackingSize === null || unitPerPackingSize === undefined) {
            throw new Error('Required parameter unitPerPackingSize was null or undefined when calling productsPackagesUpdate.');
        }
        const isDefault = requestParameters.isDefault;
        if (isDefault === null || isDefault === undefined) {
            throw new Error('Required parameter isDefault was null or undefined when calling productsPackagesUpdate.');
        }
        const isPublic = requestParameters.isPublic;
        if (isPublic === null || isPublic === undefined) {
            throw new Error('Required parameter isPublic was null or undefined when calling productsPackagesUpdate.');
        }
        const productGroup = requestParameters.productGroup;
        const secondaryCategory = requestParameters.secondaryCategory;
        const subCategory = requestParameters.subCategory;
        const picture = requestParameters.picture;
        const barcode = requestParameters.barcode;
        const width = requestParameters.width;
        const height = requestParameters.height;
        const length = requestParameters.length;
        const weight = requestParameters.weight;
        const weightUnit = requestParameters.weightUnit;
        const price = requestParameters.price;
        const vat = requestParameters.vat;
        const isNotForSell = requestParameters.isNotForSell;
        const isPin = requestParameters.isPin;
        const productPackageMaster = requestParameters.productPackageMaster;
        const productSku = requestParameters.productSku;
        const isTemporarilyUnavailable = requestParameters.isTemporarilyUnavailable;
        const isCustomName = requestParameters.isCustomName;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data',
            'application/json'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (name !== undefined) {
            localVarFormParams = localVarFormParams.append('name', <any>name) as any || localVarFormParams;
        }
        if (brand !== undefined) {
            localVarFormParams = localVarFormParams.append('brand', <any>brand) as any || localVarFormParams;
        }
        if (productGroup !== undefined) {
            localVarFormParams = localVarFormParams.append('productGroup', <any>productGroup) as any || localVarFormParams;
        }
        if (primaryCategory !== undefined) {
            localVarFormParams = localVarFormParams.append('primaryCategory', <any>primaryCategory) as any || localVarFormParams;
        }
        if (secondaryCategory !== undefined) {
            localVarFormParams = localVarFormParams.append('secondaryCategory', <any>secondaryCategory) as any || localVarFormParams;
        }
        if (subCategory !== undefined) {
            localVarFormParams = localVarFormParams.append('subCategory', <any>subCategory) as any || localVarFormParams;
        }
        if (property !== undefined) {
            localVarFormParams = localVarFormParams.append('property', <any>property) as any || localVarFormParams;
        }
        if (picture !== undefined) {
            localVarFormParams = localVarFormParams.append('picture', <any>picture) as any || localVarFormParams;
        }
        if (barcode !== undefined) {
            localVarFormParams = localVarFormParams.append('barcode', <any>barcode) as any || localVarFormParams;
        }
        if (width !== undefined) {
            localVarFormParams = localVarFormParams.append('width', <any>width) as any || localVarFormParams;
        }
        if (height !== undefined) {
            localVarFormParams = localVarFormParams.append('height', <any>height) as any || localVarFormParams;
        }
        if (length !== undefined) {
            localVarFormParams = localVarFormParams.append('length', <any>length) as any || localVarFormParams;
        }
        if (weight !== undefined) {
            localVarFormParams = localVarFormParams.append('weight', <any>weight) as any || localVarFormParams;
        }
        if (weightUnit !== undefined) {
            localVarFormParams = localVarFormParams.append('weightUnit', <any>weightUnit) as any || localVarFormParams;
        }
        if (price !== undefined) {
            localVarFormParams = localVarFormParams.append('price', <any>price) as any || localVarFormParams;
        }
        if (packingSize !== undefined) {
            localVarFormParams = localVarFormParams.append('packingSize', <any>packingSize) as any || localVarFormParams;
        }
        if (unitPerPackingSize !== undefined) {
            localVarFormParams = localVarFormParams.append('unitPerPackingSize', <any>unitPerPackingSize) as any || localVarFormParams;
        }
        if (vat !== undefined) {
            localVarFormParams = localVarFormParams.append('vat', <any>vat) as any || localVarFormParams;
        }
        if (isDefault !== undefined) {
            localVarFormParams = localVarFormParams.append('isDefault', <any>isDefault) as any || localVarFormParams;
        }
        if (isPublic !== undefined) {
            localVarFormParams = localVarFormParams.append('isPublic', <any>isPublic) as any || localVarFormParams;
        }
        if (isNotForSell !== undefined) {
            localVarFormParams = localVarFormParams.append('isNotForSell', <any>isNotForSell) as any || localVarFormParams;
        }
        if (isPin !== undefined) {
            localVarFormParams = localVarFormParams.append('isPin', <any>isPin) as any || localVarFormParams;
        }
        if (productPackageMaster !== undefined) {
            localVarFormParams = localVarFormParams.append('productPackageMaster', <any>productPackageMaster) as any || localVarFormParams;
        }
        if (productSku !== undefined) {
            localVarFormParams = localVarFormParams.append('productSku', <any>productSku) as any || localVarFormParams;
        }
        if (isTemporarilyUnavailable !== undefined) {
            localVarFormParams = localVarFormParams.append('isTemporarilyUnavailable', <any>isTemporarilyUnavailable) as any || localVarFormParams;
        }
        if (isCustomName !== undefined) {
            localVarFormParams = localVarFormParams.append('isCustomName', <any>isCustomName) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/packages/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/`;
        return this.httpClient.request<ProductPackage>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsProductGroupMastersList(requestParameters: ProductsProductGroupMastersListRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PaginatedMinimumProductGroupMasterList>;
    public productsProductGroupMastersList(requestParameters: ProductsProductGroupMastersListRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PaginatedMinimumProductGroupMasterList>>;
    public productsProductGroupMastersList(requestParameters: ProductsProductGroupMastersListRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PaginatedMinimumProductGroupMasterList>>;
    public productsProductGroupMastersList(requestParameters: ProductsProductGroupMastersListRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const brand = requestParameters.brand;
        const cursor = requestParameters.cursor;
        const name = requestParameters.name;
        const notCreatedByWs = requestParameters.notCreatedByWs;
        const pageSize = requestParameters.pageSize;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (brand !== undefined && brand !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>brand, 'brand');
        }
        if (cursor !== undefined && cursor !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>cursor, 'cursor');
        }
        if (name !== undefined && name !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>name, 'name');
        }
        if (notCreatedByWs !== undefined && notCreatedByWs !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>notCreatedByWs, 'not_created_by_ws');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'page_size');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/product-group-masters/`;
        return this.httpClient.request<PaginatedMinimumProductGroupMasterList>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsProductGroupMastersRetrieve(requestParameters: ProductsProductGroupMastersRetrieveRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<MinimumProductGroupMaster>;
    public productsProductGroupMastersRetrieve(requestParameters: ProductsProductGroupMastersRetrieveRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<MinimumProductGroupMaster>>;
    public productsProductGroupMastersRetrieve(requestParameters: ProductsProductGroupMastersRetrieveRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<MinimumProductGroupMaster>>;
    public productsProductGroupMastersRetrieve(requestParameters: ProductsProductGroupMastersRetrieveRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling productsProductGroupMastersRetrieve.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/product-group-masters/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/`;
        return this.httpClient.request<MinimumProductGroupMaster>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsProductGroupsAddProductPackageCreate(requestParameters: ProductsProductGroupsAddProductPackageCreateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ProductGroup>;
    public productsProductGroupsAddProductPackageCreate(requestParameters: ProductsProductGroupsAddProductPackageCreateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ProductGroup>>;
    public productsProductGroupsAddProductPackageCreate(requestParameters: ProductsProductGroupsAddProductPackageCreateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ProductGroup>>;
    public productsProductGroupsAddProductPackageCreate(requestParameters: ProductsProductGroupsAddProductPackageCreateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling productsProductGroupsAddProductPackageCreate.');
        }
        const addPackageToProductGroupRequestRequest = requestParameters.addPackageToProductGroupRequestRequest;
        if (addPackageToProductGroupRequestRequest === null || addPackageToProductGroupRequestRequest === undefined) {
            throw new Error('Required parameter addPackageToProductGroupRequestRequest was null or undefined when calling productsProductGroupsAddProductPackageCreate.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'multipart/form-data'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/product-groups/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/add-product-package/`;
        return this.httpClient.request<ProductGroup>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: addPackageToProductGroupRequestRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsProductGroupsCreate(requestParameters: ProductsProductGroupsCreateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ProductGroup>;
    public productsProductGroupsCreate(requestParameters: ProductsProductGroupsCreateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ProductGroup>>;
    public productsProductGroupsCreate(requestParameters: ProductsProductGroupsCreateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ProductGroup>>;
    public productsProductGroupsCreate(requestParameters: ProductsProductGroupsCreateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const productGroupRequest = requestParameters.productGroupRequest;
        if (productGroupRequest === null || productGroupRequest === undefined) {
            throw new Error('Required parameter productGroupRequest was null or undefined when calling productsProductGroupsCreate.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'multipart/form-data'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/product-groups/`;
        return this.httpClient.request<ProductGroup>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: productGroupRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsProductGroupsDestroy(requestParameters: ProductsProductGroupsDestroyRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public productsProductGroupsDestroy(requestParameters: ProductsProductGroupsDestroyRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public productsProductGroupsDestroy(requestParameters: ProductsProductGroupsDestroyRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public productsProductGroupsDestroy(requestParameters: ProductsProductGroupsDestroyRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling productsProductGroupsDestroy.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/product-groups/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsProductGroupsList(requestParameters: ProductsProductGroupsListRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<ProductGroup>>;
    public productsProductGroupsList(requestParameters: ProductsProductGroupsListRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<ProductGroup>>>;
    public productsProductGroupsList(requestParameters: ProductsProductGroupsListRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<ProductGroup>>>;
    public productsProductGroupsList(requestParameters: ProductsProductGroupsListRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const brand = requestParameters.brand;
        const name = requestParameters.name;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (brand !== undefined && brand !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>brand, 'brand');
        }
        if (name !== undefined && name !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>name, 'name');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/product-groups/`;
        return this.httpClient.request<Array<ProductGroup>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsProductGroupsPartialUpdate(requestParameters: ProductsProductGroupsPartialUpdateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ProductGroup>;
    public productsProductGroupsPartialUpdate(requestParameters: ProductsProductGroupsPartialUpdateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ProductGroup>>;
    public productsProductGroupsPartialUpdate(requestParameters: ProductsProductGroupsPartialUpdateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ProductGroup>>;
    public productsProductGroupsPartialUpdate(requestParameters: ProductsProductGroupsPartialUpdateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling productsProductGroupsPartialUpdate.');
        }
        const patchedProductGroupRequest = requestParameters.patchedProductGroupRequest;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'multipart/form-data'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/product-groups/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/`;
        return this.httpClient.request<ProductGroup>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: patchedProductGroupRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsProductGroupsRetrieve(requestParameters: ProductsProductGroupsRetrieveRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ProductGroup>;
    public productsProductGroupsRetrieve(requestParameters: ProductsProductGroupsRetrieveRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ProductGroup>>;
    public productsProductGroupsRetrieve(requestParameters: ProductsProductGroupsRetrieveRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ProductGroup>>;
    public productsProductGroupsRetrieve(requestParameters: ProductsProductGroupsRetrieveRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling productsProductGroupsRetrieve.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/product-groups/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/`;
        return this.httpClient.request<ProductGroup>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsProductGroupsUpdate(requestParameters: ProductsProductGroupsUpdateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ProductGroup>;
    public productsProductGroupsUpdate(requestParameters: ProductsProductGroupsUpdateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ProductGroup>>;
    public productsProductGroupsUpdate(requestParameters: ProductsProductGroupsUpdateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ProductGroup>>;
    public productsProductGroupsUpdate(requestParameters: ProductsProductGroupsUpdateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling productsProductGroupsUpdate.');
        }
        const productGroupRequest = requestParameters.productGroupRequest;
        if (productGroupRequest === null || productGroupRequest === undefined) {
            throw new Error('Required parameter productGroupRequest was null or undefined when calling productsProductGroupsUpdate.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'multipart/form-data'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/product-groups/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/`;
        return this.httpClient.request<ProductGroup>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: productGroupRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productsRetrieve(requestParameters: ProductsRetrieveRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Product>;
    public productsRetrieve(requestParameters: ProductsRetrieveRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Product>>;
    public productsRetrieve(requestParameters: ProductsRetrieveRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Product>>;
    public productsRetrieve(requestParameters: ProductsRetrieveRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling productsRetrieve.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/products/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/`;
        return this.httpClient.request<Product>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}
