import { inject, Injectable } from '@angular/core'
import { FeatureFlag, FlagsService, Me, UsersService } from '@core/api'
import { BehaviorSubject, finalize, forkJoin, Observable, take, tap } from 'rxjs'
import { SpinnerOverlayService } from '@service/spinner/spinner-overlay.service'
import * as Sentry from '@sentry/angular-ivy'
import { WarningBannerService } from '@service/warning-banner.service'

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  public userFlags = new BehaviorSubject<FeatureFlag[] | null>(null)

  private userData = new BehaviorSubject<Me | null>(null)
  public userData$: Observable<Me | null> = this.userData.asObservable()
  public userId: number = 0

  readonly warningBannerService = inject(WarningBannerService)

  constructor(
    private usersService: UsersService,
    private flagsService: FlagsService,
    private spinnerOverlayService: SpinnerOverlayService,
  ) {
    this.forceRefresh().subscribe()
  }

  public forceRefresh() {
    return forkJoin([this.fetchMe(), this.fetchFlags()]).pipe(take(1))
  }

  public fetchFlags(): Observable<FeatureFlag[]> {
    this.spinnerOverlayService.show()
    return this.flagsService.flagsList().pipe(
      finalize(() => {
        this.spinnerOverlayService.hide()
      }),
      tap((data: FeatureFlag[]) => {
        this.userFlags.next(data)
      }),
    )
  }

  get currentUserData(): Me | null {
    return this.userData.getValue()
  }

  private fetchMe(): Observable<Me> {
    this.spinnerOverlayService.show()
    return this.usersService.usersMeRetrieve().pipe(
      finalize(() => {
        this.spinnerOverlayService.hide()
      }),
      tap((data: Me) => {
        this.userId = data.userId

        if (window.gtag) {
          window.gtag('config', 'G-W2SC4VRJ47', {
            user_id: data.userId,
          })
        }

        // set warning banner, the bottom bar
        this.warningBannerService.showWarningBanner.set(data.hasAnyLicense || false)

        Sentry.setUser({ username: data.username })
        this.userData.next(data)
      }),
    )
  }
}
